import loadable from "@loadable/component"
import React from "react"
import arrowRight from "@images/common/ic-arrow-right.svg"
import { MAIN_BUTTON } from "../../../../../constants"
import {
  Background,
  ButtonWrapper,
  ExpertiseItemContainer,
  ExpertiseItemDescription,
  ExpertiseItemDescriptionContainer,
  ExpertiseItemTitle,
} from "./ExpertiseItem.style"

const Button = loadable(() => import("@common/Button/Button"))

const ExpertiseItem = ({ title, backgroundImage, description, link }) => (
  <ExpertiseItemContainer>
    <Background src={backgroundImage} />
    <ExpertiseItemTitle>{title}</ExpertiseItemTitle>
    <ExpertiseItemDescriptionContainer>
      <ExpertiseItemDescription>{description}</ExpertiseItemDescription>
      <ButtonWrapper>
        <Button
          text="Learn more"
          type={MAIN_BUTTON}
          bannerButtonIconPath={arrowRight}
          href={link}
        />
      </ButtonWrapper>
    </ExpertiseItemDescriptionContainer>
  </ExpertiseItemContainer>
)

export default ExpertiseItem
